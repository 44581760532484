import Flex from '@aimpact-korea/arrange-front-atomic/build/atoms/Flex';
import StyleBuilderInstane from '@aimpact-korea/arrange-front-atomic/build/utils/style-builder';
import { useState } from 'react';
import { FetchArrangeProductSubscribeByStore } from '../../../hooks/http/arrange-product-subscribe/fetch-arrange-product-subscribe-by-store';
import { FetchArrangeProductSubscribes } from '../../../hooks/http/arrange-product-subscribe/fetch-arrange-product-subscribes';
import Button from '../../atoms/Button';
import DropdownMenu from '../../molecules/DropdownMenu';
import Modal from '../../molecules/Modal';
import Table from '../../molecules/Table';
import { Calendar } from 'react-calendar';
import { Span } from '@aimpact-korea/arrange-front-atomic';
import dayjs from 'dayjs';
import OutsideClickHandler from 'react-outside-click-handler';
import { CreateArrangeProductSubscribeByStore } from '../../../hooks/http/arrange-product-subscribe/create-arrange-product-subscribe-by-store';
import { ArrangeProductSubscribeKind } from '@aimpact-korea/arrange-front-types';
import { PatchArrangeProductSubscribeByStore } from '../../../hooks/http/arrange-product-subscribe/patch-end-date-arrange-product-subscribe-by-store';
import { DeleteArrangeProductSubscribeByStore } from '../../../hooks/http/arrange-product-subscribe/delete-arrange-product-subscribe-by-store';

function ArrangeSubscribeProductByStoreSettingModal({
  isOpen,
  handleClose,
  storeId
}: {
  isOpen: boolean;
  handleClose: () => void;
  storeId: number;
}) {
  const [hasArrangeProductSubscribe, setArrangeProductSubscribe] = useState<
    boolean | null
  >(null);
  const [subscribeProduct, setSubscribeProduct] = useState('');
  const [isShowDateRange, setShowDateRange] = useState(false);
  const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'));

  const patchArrangeProductSubscribeByStore =
    PatchArrangeProductSubscribeByStore.useMutate({
      onSuccess: () => {
        alert('구독상품을 수정했습니다.');
        handleClose();
      }
    });

  const deleteArrangeProductSubscribeByStore =
    DeleteArrangeProductSubscribeByStore.useMutate({
      onSuccess: () => {
        alert('구독상품을 제거했습니다.');
        handleClose();
      }
    });

  const arrangeProductSubscribeByStore =
    FetchArrangeProductSubscribeByStore.useFetch(
      { storeId },
      {
        onSuccess: (response) => {
          setSubscribeProduct(response.data.arrangeProductSubscribeKind);
          setEndDate(response.data.endDate);

          setArrangeProductSubscribe(true);
        },
        onError: (error) => {
          if (
            error.response?.status === 404 &&
            error.response.data.error === 'Entity Not Found'
          ) {
            setArrangeProductSubscribe(false);
            return;
          }

          throw error;
        },
        suspense: false,
        staleTime: 0,
        refetchOnMount: true
      }
    );

  const arrangeProductSubscribes = FetchArrangeProductSubscribes.useFetch({
    suspense: false
  });

  const createArrangeProductSubscribeByStore =
    CreateArrangeProductSubscribeByStore.useMutate({
      onSuccess: () => {
        alert('구독상품을 지급하였습니다.');
        handleClose();
      }
    });

  function onSelectSubscribe(subscribeProduct: string) {
    setSubscribeProduct(subscribeProduct);
  }

  function onChangeEndDate(endDate: Date) {
    const eDate = dayjs(endDate).format('YYYY-MM-DD');
    setEndDate(eDate);
    setShowDateRange(false);
  }

  function onClickSave() {
    if (!subscribeProduct) {
      alert('구독상품을 선택해주세요.');
      return;
    }

    if (!endDate) {
      alert('구독상품 만료일자를 선택해주세요.');
      return;
    }

    if (hasArrangeProductSubscribe === false) {
      createArrangeProductSubscribeByStore.mutate({
        arrangeProductSubscribeKind:
          subscribeProduct as ArrangeProductSubscribeKind,
        endDate,
        storeId
      });
    }
    if (hasArrangeProductSubscribe === true) {
      patchArrangeProductSubscribeByStore.mutate({
        endDate,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        id: arrangeProductSubscribeByStore.data!.data.id,
        arrangeProductSubscribeKind:
          subscribeProduct as ArrangeProductSubscribeKind,
        storeId
      });
    }
  }

  function onClickDeleteSubscribe() {
    if (hasArrangeProductSubscribe === true) {
      deleteArrangeProductSubscribeByStore.mutate({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        id: arrangeProductSubscribeByStore.data!.data.id,
        storeId
      });
    }
  }

  return (
    <Modal sizeType="large" isOpen={isOpen} handleClose={() => handleClose()}>
      <Modal.Header>
        <Modal.Header.Title text="구독상품 수정" />
      </Modal.Header>
      {arrangeProductSubscribes.isSuccess &&
        hasArrangeProductSubscribe !== null && (
          <Modal.Body>
            <Table type="simple-table">
              <Table.Body>
                <Table.Body.Row>
                  <Table.Body.Row.Cell tag="th" width={'30%'}>
                    구독상품
                  </Table.Body.Row.Cell>
                  <Table.Body.Row.Cell>
                    <DropdownMenu
                      setValue={onSelectSubscribe}
                      value={subscribeProduct}
                    >
                      <DropdownMenu.Title isBottomLine={true}>
                        <DropdownMenu.Title.Text placeholder="구독상품을 선택해주세요."></DropdownMenu.Title.Text>
                      </DropdownMenu.Title>
                      <DropdownMenu.ItemWrapper alignType="center" size="small">
                        <DropdownMenu.ItemWrapper.ListVerticalItemGroup>
                          {arrangeProductSubscribes.data.data.arrangeProductSubscribeKinds.map(
                            (item) => (
                              <DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item
                                key={item}
                                value={item}
                              >
                                <DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item.Text
                                  text={item}
                                />
                              </DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item>
                            )
                          )}
                        </DropdownMenu.ItemWrapper.ListVerticalItemGroup>
                      </DropdownMenu.ItemWrapper>
                    </DropdownMenu>
                  </Table.Body.Row.Cell>
                </Table.Body.Row>
                <Table.Body.Row>
                  <Table.Body.Row.Cell tag="th">날짜선택</Table.Body.Row.Cell>
                  <Table.Body.Row.Cell>
                    <Span
                      style={{ cursor: 'pointer' }}
                      textAlign="text-left"
                      onClick={() => setShowDateRange(true)}
                    >
                      {endDate}
                    </Span>

                    {isShowDateRange && (
                      <OutsideClickHandler
                        onOutsideClick={() => {
                          setShowDateRange(false);
                        }}
                      >
                        <div
                          style={{
                            position: 'absolute',
                            top: '0',
                            zIndex: '100'
                          }}
                        >
                          <Calendar
                            minDate={new Date()}
                            value={dayjs(endDate).toDate()}
                            onChange={(date: Date) => {
                              onChangeEndDate(date);
                            }}
                            locale="ko"
                          />
                        </div>
                      </OutsideClickHandler>
                    )}
                  </Table.Body.Row.Cell>
                </Table.Body.Row>
              </Table.Body>
            </Table>
            {hasArrangeProductSubscribe === true && (
              <Flex justifyContent="justify-end" style={{ marginTop: '12px' }}>
                <Button
                  type="transparency"
                  isBorder={true}
                  text="삭제"
                  borderColor="secondary-color"
                  onClick={() => onClickDeleteSubscribe()}
                />
              </Flex>
            )}
          </Modal.Body>
        )}
      <Modal.Footer>
        <Flex
          flexDirection="flex-row"
          width="w-full"
          gapSize={StyleBuilderInstane.toGapSpaceType('gap-xxs')}
        >
          <Button
            text="취소"
            type="secondary-color"
            fullwidth={true}
            onClick={() => handleClose()}
          />
          <Button text="저장" fullwidth={true} onClick={() => onClickSave()} />
        </Flex>
      </Modal.Footer>
    </Modal>
  );
}

export default ArrangeSubscribeProductByStoreSettingModal;
