import {
  QueryFunctionContext,
  QueryKey,
  useInfiniteQuery
} from '@tanstack/react-query';
import axios from '../../../utils/axios';
import { isNextCursor, QueryInfiniteCallback } from '..';
import { AxiosError, AxiosResponse } from 'axios';
import { useErrorHandler } from 'react-error-boundary';

export namespace FetchAccounts {
  export type AccountRes = {
    accounts: {
      id: number;
      phone: string | null;
      name: string;
      code: string;
      lastLoginedAt: string;
      createdAt: string;
    }[];
    cursor: AccountsCursor;
  };

  export type AccountsCursor = {
    id?: number;
    standardColumn?: string;
    orderBy?: string | null;
    value?: string | number;
  };

  export type AccountsParam = {
    cursor?: AccountsCursor;
    keyword?: string;
    limit?: number;
  };

  export const KEY_STRING = 'accounts' as const;

  export type StoresQueryKeyType = [typeof KEY_STRING, AccountsParam];
  export type StoresQueryKey = StoresQueryKeyType & QueryKey;

  export async function fetchStores({
    pageParam,
    queryKey
  }: QueryFunctionContext<StoresQueryKey, AccountsCursor>) {
    const _queryKey = queryKey as StoresQueryKeyType;

    let queryParam = '';

    if (pageParam) {
      if (pageParam.id) {
        queryParam = decodeURIComponent(
          `?cursor[id]=${pageParam.id}&cursor[orderBy]=${pageParam.orderBy}&cursor[value]=${pageParam.value}&cursor[standardColumn]=${pageParam.standardColumn}`
        );
      }
    } else if (_queryKey[1].cursor?.standardColumn) {
      const urlSearchParam = new URLSearchParams();

      if (_queryKey[1].cursor.id) {
        urlSearchParam.append('cursor[id]', `${_queryKey[1].cursor.id}`);
      }
      if (_queryKey[1].cursor.orderBy) {
        urlSearchParam.append(
          'cursor[orderBy]',
          `${_queryKey[1].cursor.orderBy}`
        );
      }
      if (_queryKey[1].cursor.value) {
        urlSearchParam.append('cursor[value]', `${_queryKey[1].cursor.value}`);
      }
      if (_queryKey[1].cursor.standardColumn) {
        urlSearchParam.append(
          'cursor[standardColumn]',
          `${_queryKey[1].cursor.standardColumn}`
        );
      }

      queryParam = `?${urlSearchParam.toString()}`;
    }

    return await axios.get<AccountRes>('/accounts' + queryParam, {
      params: {
        searchKeyword: _queryKey[1].keyword || undefined,
        limit: _queryKey[1].limit
      }
    });
  }

  export const useAccounts = (
    {
      cursor: { id, orderBy, standardColumn, value } = {},
      keyword = '',
      limit = 15
    }: AccountsParam = {},
    {
      ...props
    }: QueryInfiniteCallback<
      AxiosResponse<AccountRes>,
      AxiosError,
      AxiosResponse<AccountRes>,
      StoresQueryKeyType
    > = {}
  ) => {
    const errorHandler = useErrorHandler();

    const query = useInfiniteQuery(
      [
        'accounts',
        {
          cursor: {
            id,
            orderBy,
            standardColumn,
            value
          },
          keyword,
          limit
        }
      ],
      fetchStores,
      {
        getNextPageParam: (data, datas) => {
          return isNextCursor(data.data.cursor, datas);
        },
        refetchOnWindowFocus: false,
        staleTime: 300000,
        onError: errorHandler,
        ...props
      }
    );

    return query;
  };
}
