import dayjs from 'dayjs';
import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Text from '../..//atoms/Text';
import List from '../..//molecules/List';
import Table from '../..//molecules/Table';
import {
  FetchStores,
  InitBulkOrderCountApi,
  MutationStoreActiveCustomerBackup,
  MutationStoreAddBlock,
  MutationStoreCancelBlock,
  MutationStoreDetail
} from '../../../hooks/http/store';
import SettingDropdown from '../..//molecules/SettingDropdown';
import Flex from '../..//molecules/Flex';
import Tooltip from '../..//molecules/Tooltip';
import { createSearchParams, Link, useNavigate } from 'react-router-dom';
import {
  BlockItem,
  MessageItem,
  ModifyItem,
  DeleteItem,
  BlockCancelItem,
  ArrangeProductSubscribeItem,
  StoreBulkCountInitItem
} from '../../../types/setting-menu';
import { parsePhone } from '../../../utils/format/phone';
import ProfileImage from '../..//molecules/ProfileImage';
import ClipboardCopy from '../../molecules/ClipboardCopy';
import SortingWithText, { useSort } from '../../organisms/SortingWithText';
import { StoreListSortStandard } from '../../../types/sort';
import MultiDropdownMenu from '../../molecules/MultiDropdownMenu';
import { USED_ITEMDS } from '../../../utils/dropdown-item';
import { useFilter } from '../../../hooks/useFilter';
import {
  FilterIcon,
  LocationIcon,
  MessageIcon
} from '@aimpact-korea/arrange-front-icons/build/icons';
import Button from '../../atoms/Button';
import { RequestAndroidAppReviewApi } from '../../../hooks/http/account/request-android-app-review';
import ArrangeSubscribeProductByStoreSettingModal from '../../features/ArrangeSubscribeProductByStoreSettingModal';
import Selection from '../../molecules/Selection';

const settingDropdownMenu = [
  BlockItem,
  MessageItem,
  ModifyItem,
  DeleteItem,
  ArrangeProductSubscribeItem,
  StoreBulkCountInitItem
];
const settingDropdownMenuBlockCancel = [
  BlockCancelItem,
  MessageItem,
  ModifyItem,
  DeleteItem
];
type SettingDropdownMenuValue =
  | typeof settingDropdownMenu[number]['value']
  | typeof settingDropdownMenuBlockCancel[number]['value'];

export interface Props {
  keyword?: string;
  isBlockStoresView?: boolean;
}

interface SearchFilter {
  alimtalkEnableds: typeof USED_ITEMDS[number]['value'][];
}

const StoresData: React.FC<Props> = ({
  keyword,
  isBlockStoresView = false
}) => {
  const [usedCheckeds, setUsedCheckeds] = useState(USED_ITEMDS);

  const { props, sort } = useSort<StoreListSortStandard>({
    initState: {
      orderBy: 'DESC',
      standardColumn: 'lastLoginedAt'
    }
  });

  const { filter, setFilter } = useFilter<SearchFilter>({
    alimtalkEnableds: usedCheckeds
      .filter((order) => order.checked === true)
      .map((order) => order.value)
  });

  const query = FetchStores.useStores({
    keyword,
    isBlockStores: isBlockStoresView,
    cursor: {
      standardColumn: sort?.standardColumn,
      orderBy: sort?.orderBy
    },
    alimtalkEnableds: filter?.alimtalkEnableds
  });

  const onFilterStateChange = () => {
    setFilter({
      alimtalkEnableds: usedCheckeds
        .filter((checked) => checked.checked === true)
        .map((checked) => checked.value)
    });
  };

  return (
    <InfiniteScroll
      style={{ minWidth: '2400px' }}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      dataLength={query
        .data!.pages.map((page) => page.data.stores.length)
        .reduce((sum, current) => sum + current, 0)}
      next={query.fetchNextPage}
      hasMore={query.hasNextPage ?? false}
      loader={<h4>Loading...</h4>}
    >
      <Table style={{ width: '100%' }}>
        <Table.Head>
          <Table.Row>
            <Table.Cell tag="th" alignType="center" className="col-1">
              업체코드
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              업체정보
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              공유계정
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              상품수 / 주문수
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              <MultiDropdownMenu
                placeholder="알림톡"
                IconComponent={FilterIcon}
                items={usedCheckeds}
                setChangeCheckedIndex={(idx, state) => {
                  usedCheckeds[idx].checked = state;

                  setUsedCheckeds([...usedCheckeds]);
                }}
                handleClose={onFilterStateChange}
              />
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              <SortingWithText {...props('deliveryFormCnt')}>
                택배양식
              </SortingWithText>
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              <SortingWithText {...props('createdAt')}>
                가입날짜
              </SortingWithText>
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              메시지 전송
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              고객 업로드 기능
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              구독상품
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              링크팜 개설 안내
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              권한
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              리뷰요청
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              마지막 동기화 날짜
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              <span style={{ whiteSpace: 'nowrap' }}>설정</span>
            </Table.Cell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {query.data?.pages.map((group, i) => (
            <StoresDataRow
              key={i}
              stores={group.data.stores}
              isBlockStoresView={isBlockStoresView}
            />
          ))}
        </Table.Body>
      </Table>
    </InfiniteScroll>
  );
};

export default StoresData;

interface StoresDataRowProps {
  stores: FetchStores.StoresRes['stores'];
  isBlockStoresView: boolean;
}

const StoresDataRow: React.FC<StoresDataRowProps> = React.memo(
  ({ stores, isBlockStoresView }) => {
    const navigate = useNavigate();
    const mStoreAddBlock = MutationStoreAddBlock.useMutationStoreAddBlock();
    const mStoreCancelBlock = MutationStoreCancelBlock.useMutate();
    const requestAndroidAppReviewApi = RequestAndroidAppReviewApi.useMutate();
    const asdoisadjfsidf = MutationStoreActiveCustomerBackup.useMuuuuuutate();
    const [arrangeSubscribeProductStoreId, setArrangeSubscribeProductStoreId] =
      useState<number | null>(null);
    const initBulkOrderCount = InitBulkOrderCountApi.useMutate({
      onSuccess: () => {
        alert('주문 등록 카운트가 초기화 되었습니다.');
      }
    });
    const { mutate: mutationStoreDetail } = MutationStoreDetail.useMutate();

    const redirectDetail = (storeId: number) => {
      navigate(`/store/${storeId}`);
    };

    const onSelectOption = (
      storeId: number,
      value: SettingDropdownMenuValue
    ) => {
      switch (value) {
        case 'modify':
          redirectDetail(storeId);
          break;
        case 'block':
          mStoreAddBlock.mutate({ storeId: storeId });
          break;
        case 'block-cancel':
          mStoreCancelBlock.mutate({ storeId: storeId });
          break;
        case 'arrange-product-sbuscribe':
          setArrangeSubscribeProductStoreId(storeId);
          break;
        case 'store-bulk-count-init':
          if (confirm('주문등록 카운트 초기화를 진행 하시겠습니까?')) {
            initBulkOrderCount.mutate({ storeId });
          }
          break;
      }
    };

    function handleCloseArrangeProductSubscribeModal() {
      setArrangeSubscribeProductStoreId(null);
    }

    function handleActiveCustomerBackup(storeId: number, active: boolean) {
      asdoisadjfsidf.mutate({
        isActiveCustomerBackup: active,
        storeId
      });
    }

    function handleCreateLinkfarmRequest(storeId: number, active: boolean) {
      mutationStoreDetail({
        storeId,
        createLinkfarmRequest: active
      });
    }

    function onShowSendStoreMessageModal(storeId: number) {
      navigate({
        search: createSearchParams({
          storeId: `${storeId}`,
          modalType: 'send-store-message-modal'
        }).toString()
      });
    }

    return (
      <React.Fragment>
        {arrangeSubscribeProductStoreId && (
          <ArrangeSubscribeProductByStoreSettingModal
            isOpen={true}
            handleClose={() => handleCloseArrangeProductSubscribeModal()}
            storeId={arrangeSubscribeProductStoreId}
          />
        )}
        {stores.map((store) => (
          <Table.Body.Row key={store.id}>
            <Table.Body.Row.Cell alignType="center">
              <List gap="large">
                <List.Item>
                  <Text size="small" isBold={true}>
                    No. {store.id}
                  </Text>
                </List.Item>
                {store.ceoAccount.androidVersion && (
                  <List.Item>
                    <Text isBold={true}>{store.ceoAccount.androidVersion}</Text>
                  </List.Item>
                )}
              </List>
            </Table.Body.Row.Cell>
            <Table.Body.Row.Cell alignType="left">
              <List gap="xsmall">
                <List.Item>
                  <Flex direction="row" gap="medium">
                    <Link to={{ pathname: `/store/${store.id}` }}>
                      <Text
                        isBold={true}
                        textDecoration="underline"
                        colorType="primary-color"
                      >
                        {store.company}
                      </Text>
                    </Link>
                    <Flex direction="row" alignItems="center" gap="small">
                      <Text tag="p">{store.ceoAccount.name}</Text>
                      <ClipboardCopy
                        text={`${store.address} ${store.detailedAddress ?? ''}`}
                      >
                        <Tooltip
                          sizeType="small"
                          label={`${store.address} ${
                            store.detailedAddress ?? ''
                          }`}
                        >
                          <div style={{ cursor: 'pointer' }}>
                            <LocationIcon
                              width={16}
                              height={16}
                              colorType="text-primary-500"
                            />
                          </div>
                        </Tooltip>
                      </ClipboardCopy>
                    </Flex>
                  </Flex>
                </List.Item>
                <List.Item>
                  <Text>
                    {store.ceoAccount.phone &&
                      parsePhone(store.ceoAccount.phone)}
                  </Text>
                </List.Item>
                <List.Item>
                  <Text>{store.ceoAccount.email}</Text>
                </List.Item>
              </List>
            </Table.Body.Row.Cell>
            <Table.Body.Row.Cell alignType="left">
              <Flex direction="row" gap="small" justifyContent="center">
                <Tooltip
                  label={[store.ceoAccount.name, `${store.ceoAccount.id}`]}
                  sizeType="xsmall"
                >
                  <ProfileImage
                    sizeType="small"
                    outline={true}
                    src={store.ceoAccount.kakaoProfileImg ?? ''}
                  />
                </Tooltip>
                {store.sharedStore
                  .filter((user) => user.id !== store.ceoAccount.id)
                  .map((user) => (
                    <Tooltip
                      label={[user.name, `${user.id}`]}
                      key={user.id}
                      sizeType="xsmall"
                    >
                      <ProfileImage
                        sizeType="small"
                        src={user.kakaoProfileImg ?? ''}
                      />
                    </Tooltip>
                  ))}
              </Flex>
            </Table.Body.Row.Cell>
            <Table.Body.Row.Cell alignType="center">
              <Flex gap="medium" direction="column">
                <Flex gap="large" direction="row">
                  <Text isBold={true} colorType="gray-color">
                    상품수
                  </Text>
                  <Link
                    to={{
                      pathname: `/product`,
                      search: `?storeId=${store.id}`
                    }}
                  >
                    <Text
                      tag="span"
                      isBold={true}
                      textDecoration="underline"
                      colorType="primary-color"
                    >
                      {store.productsCount} 개
                    </Text>
                  </Link>
                </Flex>
                <Flex gap="large" direction="row">
                  <Text isBold={true} colorType="gray-color">
                    주문수
                  </Text>
                  <Link
                    to={{
                      pathname: `/transaction`,
                      search: `?storeId=${store.id}`
                    }}
                  >
                    <Text
                      tag="span"
                      textDecoration="underline"
                      colorType="primary-color"
                      isBold={true}
                    >
                      {store.orderGroupCount}&nbsp;건
                    </Text>
                  </Link>
                </Flex>
                <Flex gap="large" direction="row">
                  <Text isBold={true} colorType="gray-color">
                    지난달
                  </Text>
                  <Text
                    tag="span"
                    textDecoration="underline"
                    colorType="primary-color"
                    isBold={true}
                  >
                    {store.orderGroupCountOfBeforeMonth}&nbsp;건
                  </Text>
                </Flex>
              </Flex>
            </Table.Body.Row.Cell>
            <Table.Body.Row.Cell alignType="center">
              <Text
                isBold={true}
                colorType={
                  store.ceoAccount.alimtalkEnabled === true
                    ? 'primary-color'
                    : 'gray-color'
                }
              >
                {store.ceoAccount.alimtalkEnabled === true ? '사용' : '미사용'}
              </Text>
            </Table.Body.Row.Cell>
            <Table.Body.Row.Cell alignType="center">
              {store.deliveryFormCount}개
            </Table.Body.Row.Cell>
            <Table.Body.Row.Cell alignType="center">
              <Flex direction="column" gap="medium" alignItems="center">
                <Flex direction="row" gap="large">
                  <Text isBold={true} colorType="gray-color">
                    최종접속
                  </Text>
                  <Text>
                    {dayjs(store.ceoAccount.lastLoginedAt).format(
                      'YYYY-MM-DD HH:mm:ss'
                    )}
                  </Text>
                </Flex>
                <Flex direction="row" gap="large">
                  <Text isBold={true} colorType="gray-color">
                    가입날짜
                  </Text>
                  <Text>
                    {dayjs(store.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                  </Text>
                </Flex>
              </Flex>
            </Table.Body.Row.Cell>
            <Table.Body.Row.Cell alignType="center">
              <Text
                style={{ cursor: 'pointer' }}
                onClick={() => onShowSendStoreMessageModal(store.id)}
              >
                <MessageIcon />
              </Text>
            </Table.Body.Row.Cell>
            <Table.Body.Row.Cell alignType="center">
              {/* <Flex alignItems="center" justifyContent="center">
                {store.ceoAccount.isChannelTalk && (
                  <img
                    src="https://channel.io/favicon.ico"
                    style={{ width: '24px', height: '24px' }}
                  />
                )}
              </Flex> */}
              <Selection>
                <Selection.Input
                  type="toggle"
                  id="aa_1"
                  isChecked={store.isActiveCustomerBackup}
                  onChangeChecked={() =>
                    handleActiveCustomerBackup(
                      store.id,
                      !store.isActiveCustomerBackup
                    )
                  }
                />
              </Selection>
            </Table.Body.Row.Cell>
            <Table.Body.Row.Cell alignType="center">
              {store.arrangeProductSubscribe?.arrangeProductSubscribeKind}

              {store.arrangeProductSubscribe?.endDate ? (
                <>
                  <br />
                  {store.arrangeProductSubscribe.endDate}
                </>
              ) : (
                ''
              )}
            </Table.Body.Row.Cell>
            <Table.Body.Row.Cell alignType="center">
              {' '}
              {!store.barofarmExist ? (
                <Selection>
                  <Selection.Input
                    type="toggle"
                    id="aa_31"
                    isChecked={store.createLinkfarmRequest}
                    onChangeChecked={() =>
                      handleCreateLinkfarmRequest(
                        store.id,
                        !store.createLinkfarmRequest
                      )
                    }
                  />
                </Selection>
              ) : undefined}
            </Table.Body.Row.Cell>
            <Table.Body.Row.Cell alignType="center">
              {store.ceoAccount.firstStartArrangeMini ? (
                <Text colorType="primary-color" isBold={true}>
                  활성화
                </Text>
              ) : (
                <Text colorType="secondary-color" isBold={true}>
                  비활성화
                </Text>
              )}
            </Table.Body.Row.Cell>
            <Table.Body.Row.Cell alignType="center">
              {store.ceoAccount.androidAppReviewType === 'NO_ACTION' && (
                <Button
                  text="리뷰요청"
                  onClick={() =>
                    requestAndroidAppReviewApi.mutate({
                      accountId: store.ceoAccount.id
                    })
                  }
                />
              )}
              {store.ceoAccount.androidAppReviewType === 'PENDING' &&
                '요청완료'}
            </Table.Body.Row.Cell>
            <Table.Body.Row.Cell alignType="center">
              <Text>{store.lastAutoOrderSyncBackup}</Text>
            </Table.Body.Row.Cell>

            <Table.Body.Row.Cell alignType="center">
              <div style={{ width: '120px' }}>
                <SettingDropdown
                  settingMenus={
                    isBlockStoresView
                      ? settingDropdownMenuBlockCancel
                      : settingDropdownMenu
                  }
                  onSelect={(v) =>
                    onSelectOption(store.id, v as SettingDropdownMenuValue)
                  }
                />
              </div>
            </Table.Body.Row.Cell>
          </Table.Body.Row>
        ))}
      </React.Fragment>
    );
  }
);

StoresDataRow.displayName = 'StoresDataRow';
