import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Text from '../../atoms/Text';
import List from '../../molecules/List';
import Table from '../../molecules/Table';
import SortingWithText, { useSort } from '../../organisms/SortingWithText';
import { AccountListSortStandard } from '../../../types/sort';
import { FetchAccounts } from '../../../hooks/http/account/fetch-account';
import Button from '../../atoms/Button';

export interface Props {
  keyword?: string;
}

const AccountsData: React.FC<Props> = ({ keyword }) => {
  const { props, sort } = useSort<AccountListSortStandard>({
    initState: {
      orderBy: 'DESC',
      standardColumn: 'createdAt'
    }
  });

  const query = FetchAccounts.useAccounts({
    keyword,
    cursor: {
      standardColumn: sort?.standardColumn,
      orderBy: sort?.orderBy
    }
  });

  return (
    <InfiniteScroll
      style={{ maxWidth: '2400px' }}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      dataLength={query
        .data!.pages.map((page) => page.data.accounts.length)
        .reduce((sum, current) => sum + current, 0)}
      next={query.fetchNextPage}
      hasMore={query.hasNextPage ?? false}
      loader={<h4>Loading...</h4>}
    >
      <Table style={{ width: '100%' }}>
        <Table.Head>
          <Table.Row>
            <Table.Cell tag="th" alignType="center" className="col-1">
              유저 No.
              <br />
              코드
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              이름
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              번호
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              마지막 로그인 시간
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              <SortingWithText {...props('createdAt')}>
                가입날짜
              </SortingWithText>
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              쿠폰 발급
            </Table.Cell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {query.data?.pages.map((group, i) => (
            <AccountsDataRow key={i} accounts={group.data.accounts} />
          ))}
        </Table.Body>
      </Table>
    </InfiniteScroll>
  );
};

export default AccountsData;

interface AccountsDataRowProps {
  accounts: FetchAccounts.AccountRes['accounts'];
}

const AccountsDataRow: React.FC<AccountsDataRowProps> = React.memo(
  ({ accounts }) => {
    const onClickShowCouponIssueDialog = () => {
      console.log('onClickShowCouponIssueDialog');
    };

    return (
      <React.Fragment>
        {accounts.map((account) => (
          <Table.Body.Row key={account.id}>
            <Table.Body.Row.Cell alignType="center">
              <List gap="large">
                <List.Item>
                  <Text size="small" isBold={true}>
                    No. {account.id}
                    <br />
                    {account.code}
                  </Text>
                </List.Item>
              </List>
            </Table.Body.Row.Cell>
            <Table.Body.Row.Cell alignType="center">
              <Text>{account.name}</Text>
            </Table.Body.Row.Cell>
            <Table.Body.Row.Cell alignType="center">
              <Text>{account.phone}</Text>
            </Table.Body.Row.Cell>
            <Table.Body.Row.Cell alignType="center">
              <Text>{account.lastLoginedAt}</Text>
            </Table.Body.Row.Cell>
            <Table.Body.Row.Cell alignType="center">
              <Text>{account.createdAt}</Text>
            </Table.Body.Row.Cell>
            <Table.Body.Row.Cell alignType="center">
              <Button
                text="쿠폰 발급"
                onClick={() => onClickShowCouponIssueDialog()}
              />
            </Table.Body.Row.Cell>
          </Table.Body.Row>
        ))}
      </React.Fragment>
    );
  }
);

AccountsDataRow.displayName = 'AccountsDataRow';
