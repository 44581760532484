import {
  QueryFunctionContext,
  QueryKey,
  useQuery,
  useQueryClient,
  UseQueryOptions
} from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import axios from '../../../utils/axios';
import { useErrorHandler } from 'react-error-boundary';
import { ArrangeProductSubscribeKind } from '@aimpact-korea/arrange-front-types';

export namespace FetchArrangeProductSubscribeByStore {
  export type ResponseType = {
    id: number;
    storeId: number;
    arrangeProductSubscribeKind: ArrangeProductSubscribeKind;
    endDate: string;
    createdAt: string;
  };

  export type RequestType = {
    storeId: number;
  };

  export const KEY_STRING = 'fetch-arrange-product-subscribe-by-store' as const;

  export type QueryKeyType = [typeof KEY_STRING, RequestType];
  export type QueryKeyType2 = QueryKeyType & QueryKey;

  export async function fetchData({
    queryKey
  }: QueryFunctionContext<QueryKeyType2>) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const _queryKey = queryKey as QueryKeyType;

    return await axios.get<ResponseType>(
      `/arrange-product-subscribe/store/${_queryKey[1].storeId}`
    );
  }

  export const useRefetchData = () => {
    const queryClient = useQueryClient();

    const refetchByStore = (storeId: number) => {
      queryClient.refetchQueries([KEY_STRING, { storeId }]);
    };

    return { refetchByStore };
  };

  export const useFetch = (
    { storeId }: RequestType,
    {
      ...props
    }: UseQueryOptions<
      AxiosResponse<ResponseType>,
      AxiosError,
      AxiosResponse<ResponseType>,
      QueryKeyType
    > = {}
  ) => {
    const errorHandler = useErrorHandler();

    const query = useQuery([KEY_STRING, { storeId }], fetchData, {
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      refetchOnReconnect: true,
      staleTime: 300000,
      onError: errorHandler,
      ...props
    });

    return query;
  };
}
